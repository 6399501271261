import React from "react";
import Layout from "../components/layout";
import cbr1 from "../images/cbr1.jpeg";
import percolacao2 from "../images/percolacao2.jpeg";
import SEO from "../components/seo";

function Percolacao() {
  return (
    <Layout>
      <SEO
        keywords={[`Golden`, `Sondagens`, `SPT`, `Construção`]}
        title="Percolação e Ensaio CBR"
      />

      <section
        style={{
          height: "300px",
          backgroundImage: `linear-gradient(to right, hsla(0, 0%, 0%, 1), hsla(0, 0%, 0%, 0.3)), url(${cbr1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        }}
      >
        <h1 className="font-bold text-white ml-8 mt-24 uppercase base-title-golden">
          Percolação e Ensaio CBR
        </h1>
      </section>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 m-2 xl:m-12">
        <div>
          <h3>
          Nossos serviços de percolação e ensaio CBR são vitais para avaliar as propriedades mecânicas do solo. 
          O ensaio de percolação analisa a capacidade de drenagem, enquanto o ensaio CBR avalia a resistência do solo à penetração. 
          Essas informações são cruciais para o design de pavimentos e estruturas de fundação. 
          Com equipamentos especializados e técnicos experientes, garantimos resultados confiáveis para a tomada de decisões fundamentais em seus projetos.
          </h3>
        </div>
        <img src={percolacao2} />
      </div>
    </Layout>
  );
}

export default Percolacao;
